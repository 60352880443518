import styled from "styled-components";

const Style = styled.div`
  .icon-textbox {
    fill: #fff;

    path {
      fill: inherit;
    }

    height: 48px;
    display: flex;
    align-items: center;

    :hover {
      cursor: pointer;
      background-color: #000;
    }
  }
`;

export default Style;
