import styled from "styled-components";

const Style = styled.div`
  display: flex;
  justify-content: space-between;

  height: 48px;
  line-height: 48px;
  background-color: #2c2c2c;
  color: #ffffff;
`;

export default Style;
