import styled from "styled-components";

const Style = styled.div`
  background-color: #e5e5e5;
  height: 100vh;

  overflow: hidden;
`;

export default Style;
