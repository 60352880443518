import * as Diff from "diff";
import { fabric } from "fabric";
import OpentypeIText from "./OpentypeIText";
import { v4 as uuidv4 } from "uuid";
import { findIndex } from "lodash";

export const groupBoundedOption = {
  async: true,
  superType: "husblizer",
  cornerColor: "#18a0fb",
  borderColor: "#18a0fb",
  borderScaleFactor: 2,
  lockScalingFlip: true,
  originX: "center",
  originY: "center",
  objectCaching: false,
  padding: -1,
};

const BoundedText = fabric.util.createClass(fabric.Group, {
  async: true,
  type: "textBoxPro",
  lockUniScalingWithSkew: false,
  debug: false,
  version: "4.3.1",
  lineSpacing: 1,
  _textWithoutPrefixSuffix: "",
  initialize: function (options: any) {
    if (options.fontFamily) {
      options.fontFamily = this._sanitizeFontFamily(options.fontFamily);
    }

    options.version = options.version || BoundedText.prototype.version;
    const defaults = {
      width: options.width || 300,
      height: options.height || 300,
      fontSize: options.fontSize || 200,
      originX: "center",
      originY: "center",
      textAlign: options.textAlign || "center",
      centeredRotation: true,
      color: options.color || "black",
      fontFamily: options.fontFamily || "Arial",
      fontUrl:
        options.fontUrl ||
        "https://cdn.customily.com/fonts/bubblestyle/arial.ttf",
      backgroundColor: this.debug ? "#f7dacf" : "rgba(0,0,0,0)",
      tracking: options.tracking || 0,
      version: options.version,
      stroke: "#000000",
      lockScalingFlip: true,
      fill: options.fill || "#000000",
      opentypeStrokeWidth: options.strokeWidth || 0,
      opentypeStroke: options.stroke || "#000000",
    };
    let text = options.originalText;
    if (options.caps) {
      text = options.originalText.toUpperCase();
    }
    const firstText = new OpentypeIText("A", { ...defaults, opacity: 0 });
    const txtFilter = new OpentypeIText(text, defaults);
    this.set("originalText", text);
    this.set("_textWithoutPrefixSuffix", text);
    const r = new fabric.Rect({
      strokeDashArray: options.strokeDashArray,
      originX: "center",
      originY: "center",
      stroke: "#000000",
      strokeWidth: 0,
      width: options.width || 300,
      height: options.height || 300,
      fill: "rgba(0, 0, 0, 0)",
    });
    this.set("caps", false);
    this.callSuper(
      "initialize",
      [firstText, r],
      Object.assign(options, groupBoundedOption)
    );
    setTimeout(() => {
      this.callSuper(
        "initialize",
        [txtFilter, r],
        Object.assign(options, groupBoundedOption)
      );
    });
    this.setTextAlign(options.textAlign || "center");
    this.objectCaching = false;
    this._updateFont();
    this.on({
      scaling: function (val: any) {
        if (this.lockUniScalingWithSkew) {
          let s = 1;
          s = "scaleX" === val.transform.action ? this.scaleX : this.scaleY;
          this.scaleX = s;
          this.scaleY = s;
        }
        const w = this.width * this.scaleX;
        const height = this.height * this.scaleY;
        this.scaleX = 1;
        this.scaleY = 1;
        this.setWidth(w);
        this.setHeight(height);
        this.setTextAlign(this.getTextAlign());
      },
      added: function () {
        // this.cornerSize = .025 * this.canvas.width;
        this.transparentCorners = true;
        // this._updateFont();
        this.updateFromGroupScaling();
        this.setPrefixSuffix(text, options.prefix || "", options.suffix || "");
        // this.setWidth(this.width);
      },
    });
  },
  updateFromGroupScaling: function () {
    const w = this.width * this.scaleX;
    const height = this.height * this.scaleY;
    this.scaleX = 1;
    this.scaleY = 1;
    this.setWidth(w);
    this.setHeight(height);
    this.setTextAlign(this.getTextAlign());
  },
  _set: function (key: any, value: any) {
    if ("textAlign" === key) {
      this.setTextAlign(value);
    } else {
      if ("caps" === key) {
        this.setCaps(value);
      } else {
        if ("text" === key) {
          this.setText(value);
        } else {
          if ("outlineWidth" === key) {
            this.setOutlineWidth(value);
          } else {
            if ("fontFamily" === key) {
              this.setFontFamily(value);
            } else {
              if ("fill" === key && value.constructor === fabric.Pattern) {
                this.item(0).set("fillPattern", value);
              } else {
                if ("version" === key) {
                  /** @type {!Object} */
                  this.version = value;
                  this.item(0).set("version", value);
                } else {
                  if ("lineSpacing" === key) {
                    this.callSuper("_set", key, value);
                    this.item(0).set("lineSpacing", value);
                  } else {
                    if ("ligatures" === key) {
                      this.callSuper("_set", key, value);
                      this.item(0).set("ligatures", value);
                    } else {
                      this.callSuper("_set", key, value);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  getTextPaths: function () {
    return this.item(0).getTextPaths(this.getWidth(), this.getHeight());
  },
  setTopLeft: function (top: number, left: number) {
    this.set({
      top: top,
      left: left,
    });
  },
  setPrefixSuffix: function (text: string, prefix: string, suffix: string) {
    this.prefix = prefix;
    this.suffix = suffix;
    this.setText(text);
  },
  setSkewXY: function (skewX: number, skewY: number) {
    this.set({
      skewX: skewX,
      skewY: skewY,
    });
  },
  setMinMaxSize: function (text: string, minSize: number, maxSize: number) {
    this.minSize = minSize;
    this.maxSize = maxSize;
    this.setText(text, true);
  },
  setLetterSpacing: function (letterSpacing: number) {
    this.item(0).set("tracking", letterSpacing || 0);
    this._updateFont();
  },
  setLineHeight: function (lineHeight: number) {
    this.set({ lineSpacing: lineHeight });
    this._updateFont();
  },
  setTextAlign: function (align: any) {
    let origWidth;
    let width_box;
    if ("opentype-itext" == this.item(0).type) {
      this.item(0).set("textAlign", align);
      this.item(0).set("dirty", true);
      this.canvas && this.canvas?.renderAll();
      switch (align) {
        case "center":
          this.item(0).set({
            left: 0,
            top: 0,
          });
          break;
        case "left":
        case "justify":
          origWidth = this.item(0).width;
          width_box = (this.getWidth() - origWidth) / 2;
          this.item(0).set({
            left: -width_box,
            top: 0,
          });
          break;
        case "right":
          origWidth = this.item(0).width;
          width_box = (this.getWidth() - origWidth) / 2;
          this.item(0).set({
            left: width_box,
            top: 0,
          });
      }
      // this._updateFont()
      this.canvas && this.canvas?.renderAll();
    }
  },
  setTrackingAmount: function (state: any) {
    this.item(0).set("tracking", state || 0);
  },
  getTrackingAmount: function () {
    return this.item(0).tracking || 0;
  },
  getOutlineWidth: function () {
    return this.item(0).opentypeStrokeWidth || 0;
  },
  setOutlineWidth: function (strokeWidth: number) {
    this.item(0).set("opentypeStrokeWidth", strokeWidth || 0);
    this._updateFont();
  },
  getOutlineColor: function () {
    return this.item(0).opentypeStroke || "#000000";
  },
  setOutlineColor: function (rgba: string) {
    this.item(0).set("opentypeStroke", rgba || "#000000");
    this._updateFont();
  },
  getTextAlign: function () {
    return this.item(0).textAlign;
  },
  setCursorColor: function (p_id: any) {
    /** @type {string} */
    this.item(0).cursorColor = p_id;
  },
  setStroke: function (width: any) {
    this.item(1).set("stroke", width);
  },
  setStrokeWidth: function (width: any) {
    this.item(1).set("strokeWidth", width);
  },
  getStroke: function () {
    return this.item(1).stroke;
  },
  setColor: function (color: string) {
    this.item(0).set({
      fill: color,
    });
    this.canvas?.renderAll();
  },
  getColor: function () {
    return this.item(0).fill;
  },
  getText: function () {
    return this._textWithoutPrefixSuffix;
  },
  getTextWithLines: function () {
    return this.item(0).getText();
  },
  setText: function (id: any) {
    if (!id) {
      id = "";
    }
    this.set("_textWithoutPrefixSuffix", id);
    if (this.caps) {
      id = id.toUpperCase();
    }
    id = (this.prefix || "") + id + (this.suffix || "");
    // let n = start ? '' : this.originalText;
    id = this._computeTrimmedText(id, id, this.item(0), this);
    this.set("originalText", id);
    this.item(0).set("text", id);
    this._updateFont();
    return id;
  },
  forceFontSize: function (t: any) {
    this.item(0).set("fontSize", t);
    this.setTextAlign(this.getTextAlign());
  },
  updateFontSize: function () {
    this._updateFont();
  },
  setFontSize: function (size: any) {
    /** @type {number} */
    this.item(0).fontSize = size;
    this._updateFont();
  },
  getFontSize: function () {
    return this.item(0).fontSize;
  },
  _sanitizeFontFamily: function (qNameAsString: any) {
    return (
      qNameAsString.indexOf("'") < 0 &&
        (qNameAsString = "'" + qNameAsString + "'"),
      qNameAsString
    );
  },
  setFontFamily: function (value: any) {
    value = this._sanitizeFontFamily(value);
    this.item(0).set("fontFamily", value);
    this._updateFont();
    // this._updateFont();
  },
  getFontFamily: function () {
    return this.item(0).fontFamily;
  },
  setWidth: function (width: any) {
    if (!width) {
      /** @type {number} */
      width = 0;
    }
    this.set("width", width);
    this.item(1).set("width", width);
    this.item(0).set("justifyWidth", width);
    this._updateFont();
  },
  getWidth: function () {
    return this.width * this.scaleX;
  },
  setHeight: function (height: any) {
    if (!height) {
      /** @type {number} */
      height = 0;
    }
    this.set("height", height);
    this.item(1).set("height", height);
    this._updateFont();
  },
  getHeight: function () {
    return this.height * this.scaleY;
  },
  setMinSizePx: function (canCreateDiscussions: any) {
    this.minSize = canCreateDiscussions;
    this._updateFont();
  },
  setMaxSizePx: function (canCreateDiscussions: any) {
    this.maxSize = canCreateDiscussions;
    this._updateFont();
  },
  setMultiline: function (multiline: any) {
    this.multiline = multiline;
    this._updateFont();
  },
  setCaps: function (uuid: any) {
    /** @type {!Object} */
    this.caps = uuid;
    if (uuid) {
      this.setText(this.getText().toUpperCase(), true);
    }
    this._updateFont();
  },
  _computeTrimmedText: function (e: any, o: any, b: any, el: any) {
    const d = Diff.diffChars(o, e);
    let a = "";
    const refs = [];
    let j = 0;
    for (; j < d.length; ++j) {
      if (d[j].removed || d[j].added) {
        if (d[j].added) {
          let messageStart = 0;
          if (j > 0) {
            let i = 1;
            for (; j - i >= 0; ++i) {
              if (!d[j - i].removed) {
                messageStart = messageStart + d[j - i].value.length;
              }
            }
          }
          refs.push({
            value: d[j].value,
            start: messageStart,
          });
        }
      } else {
        a = a + d[j].value;
      }
    }
    let break2 = false;
    j = 0;
    for (; j < refs.length && !break2; ++j) {
      let _a = a;
      let i = 0;
      for (; i < refs[j].value.length; ++i) {
        const idx = refs[j].start + i;
        a = a.substr(0, idx) + refs[j].value[i] + a.substr(idx);
        el.set("originalText", a);
        try {
          if ((b.set("text", a), el._updateFont(el, b))) {
            a = _a;
            break2 = true;
            break;
          }
          _a = a;
        } catch (g) {
          return (
            (a = _a),
            el.set("originalText", a),
            b.set("text", a),
            el._updateFont(el, b),
            a
          );
        }
      }
    }
    return a;
  },
  _updateFont: function ($this: any, itext: any) {
    if (
      ($this || ($this = this), itext || (itext = $this.item(0)), $this.canvas)
    ) {
      const width = $this.getWidth() || $this.width;
      const height = $this.getHeight() || $this.height;
      const minSize = $this.minSize;
      const maxSize = $this.maxSize;
      const multiline = (itext.fontFamily, $this.multiline);
      let ok = false;
      const p = itext;
      p.text = $this.get("originalText");
      $this.item(0).set("dirty", true);
      this.canvas?.renderAll();
      let widthRatio =
        width /
        ("justify" == $this.getTextAlign() ? p.totalWidthNoJustify : p.width);
      let heightRatio = height / p.height;
      let factor = Math.min(widthRatio, heightRatio);
      let fontSize = p.fontSize * factor;
      itext.text = $this.get("originalText");
      if (fontSize < minSize) {
        let str = (" " + $this.get("originalText")).slice(1);
        let localization = (" " + $this.get("originalText")).slice(1);
        p.fontSize = minSize;
        if (multiline) {
          const result = (function (elem, width, canvas, $this) {
            const tmp = elem;
            const x = tmp.text;
            const existingChoices = $this.get("originalText").split(" ");
            const constrTypes = [];
            let c = existingChoices[0];
            let i = 1;
            for (; i < existingChoices.length; i++) {
              const value = existingChoices[i];
              elem.set("text", c + " " + value);
              canvas?.renderAll();
              if (
                ("justify" == $this.getTextAlign()
                  ? tmp.totalWidthNoJustify
                  : tmp.width) < width
              ) {
                c = c + (" " + value);
              } else {
                constrTypes.push(c);
                c = value;
              }
            }
            return (
              constrTypes.push(c),
              tmp.set("text", x),
              canvas?.renderAll(),
              constrTypes
            );
          })(p, width, $this.canvas, $this);
          str = result.join("\n");
          localization = result.join("\n");
        }
        let end = 0;
        for (; end <= str.length && fontSize < minSize; ++end) {
          localization = str.slice(0, str.length - end);
          p.text = localization;
          $this.item(0).set("dirty", true);
          $this.canvas?.renderAll();
          widthRatio =
            width /
            ("justify" == $this.getTextAlign()
              ? p.totalWidthNoJustify
              : p.width);
          heightRatio = height / p.height;
          factor = Math.min(widthRatio, heightRatio);
          fontSize = p.fontSize * factor;
        }
        if (fontSize > maxSize) {
          fontSize = maxSize;
        } else if (fontSize < minSize) {
          fontSize = minSize;
        }
        itext.fontSize = fontSize;
        itext.text = localization;
        $this.fontSize = itext.fontSize;
        if (end > 1) {
          ok = true;
        }
      } else {
        itext.fontSize = fontSize > maxSize ? maxSize : fontSize;
        $this.fontSize = itext.fontSize;
      }
      $this.setTextAlign(this.getTextAlign());
      $this.canvas?.renderAll();
      $this.item(0).set("dirty", true);
      $this.canvas?.renderAll();
      return ok;
    }
  },
  updateText: function (name: string, value: string) {
    this.set("originalText", value);
    // this.item(0).set("text", input.value)
    this._updateFont();
  },

  updateCalcPostion: function (name: string, value: number) {
    if (name === "left") {
      this.left = value; // - this.width / 2; // rect
    }

    if (name === "top") {
      this.top = value; //  - this.height / 2; // rect
    }

    if (name === "width") {
      this.setWidth(value);
    }

    if (name === "height") {
      this.setHeight(value);
    }

    if (name === "angle") {
      this.angle = value;
    }

    if (name === "fontSize") {
      this.setFontSize(value);
    }

    if (name === "minSize" && value <= this.maxSize) {
      this.minSize = value;
    }

    if (name === "maxSize") {
      this.maxSize = value;
    }

    if (name === "skewX") {
      this.skewX = value;
    }

    if (name === "skewY") {
      this.skewY = value;
    }

    if (name === "strokeWidth") {
      this.setOutlineWidth(value);
      // this._updateFont()
    }

    if (name === "elementId") {
      this.elementId = value;
    }
    this.canvas?.renderAll();
  },
  updateColor: function (name: string, value: string) {
    if (name === "stroke") {
      this.setOutlineColor(value);
    }

    if (name === "fill") {
      this.setColor(value);
    }

    this.canvas?.renderAll();
  },
  updateAlign: function (align: string) {
    this.setTextAlign(align);
    // this._updateFont();
  },
  updateFont: function (font: string, fontUrl: string) {
    this.fontUrl = fontUrl;
    this.setFontFamily(font);
  },
  __updateView: function () {
    this.visible = !this.visible;
    this.canvas.renderAll.bind(this.canvas);
    this.canvas?.renderAll();
  },
  __updateLock: function () {
    this.selectable = !this.selectable;
    this.evented = !this.evented;

    this.canvas.renderAll.bind(this.canvas);
    this.canvas?.renderAll();
  },
  changeMultiline: function (name: string, value: boolean) {
    this.setMultiline(value);
  },
  handlePickColor: function () {
    this.canvas.defaultCursor = "copy";
    this.canvas?.renderAll();
  },

  handlePickStroke: function () {
    this.canvas.defaultCursor = "copy";
    this.canvas?.renderAll();
  },
  countStepForward: function () {
    let step = 0;
    const objects = this.canvas.getObjects();
    const indexThis = findIndex(objects, { id: this.id });
    let i = indexThis + 1;
    const length = objects.length;
    while (i < length) {
      step++;
      if (objects[i].id) {
        return step;
      }
      i++;
    }
    return step;
  },
  countStepBackward: function () {
    let step = 0;
    const objects = this.canvas.getObjects();
    const indexThis = findIndex(objects, { id: this.id });
    let i = indexThis - 1;
    let count = 0;
    while (i >= 1) {
      if (objects[i].id) {
        count++;
      }

      if (count === 1) {
        step++;
      } else {
        if (count === 2) {
          return step;
        }
      }
      i--;
    }

    return step;
  },
  setZIndex: function (name: string) {
    if (name === "forward") {
      const stepForward = this.countStepForward();
      for (let i = 0; i < stepForward; i++) {
        this.canvas.bringForward(this);
      }
    } else {
      const stepBackward = this.countStepBackward();
      for (let i = 0; i < stepBackward; i++) {
        this.canvas.sendBackwards(this);
      }
    }
    this.canvas?.renderAll();
  },
  cloneObject: function () {
    const { onAdd, propertiesToInclude } = this;
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    if (
      typeof activeObject.cloneable !== "undefined" &&
      !activeObject.cloneable
    ) {
      return;
    }
    activeObject.clone((clonedObj: any) => {
      this.canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
      });
      if (clonedObj.type === "activeSelection") {
        const activeSelection = clonedObj as fabric.ActiveSelection;
        activeSelection.canvas = this.canvas;
        activeSelection.forEachObject((obj: any) => {
          obj.set("id", uuidv4());
          this.canvas.add(obj);
          this.objects = this.getObjects();
          if (obj.dblclick) {
            obj.on("mousedblclick", this.eventHandler.object.mousedblclick);
          }
        });
        if (onAdd) {
          onAdd(activeSelection);
        }
        activeSelection.setCoords();
      } else {
        if (activeObject.id === clonedObj.id) {
          clonedObj.set("id", uuidv4());
        }
        this.canvas.add(clonedObj);
        this.canvas.objects = this.canvas.getObjects();
        if (clonedObj.dblclick) {
          clonedObj.on("mousedblclick", this.eventHandler.object.mousedblclick);
        }
        if (onAdd) {
          onAdd(clonedObj);
        }
      }
      this.canvas.setActiveObject(clonedObj);
      this.canvas.requestRenderAll();
    }, propertiesToInclude);
  },
  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      id: this.id,
      objects: null,
      name: this.name,
      elementId: this.elementId,
      value: this.value,
      fontSize: this.item(0).fontSize,
      fontId: "",
      fontFamily: this.item(0).fontFamily,
      fonts: [],
      fontUrl: this.fontUrl,
      minSize: this.minSize,
      maxSize: this.maxSize,
      tracking: this.item(0).tracking,
      lineSpacing: this.lineSpacing,
      textAlign: this.item(0).textAlign,
      caps: this.caps,
      multiline: this.multiline,
      fill: this.item(0).fill,
      fillId: "",
      fills: [],
      stroke: this.item(0).opentypeStroke,
      strokeWidth: this.item(0).opentypeStrokeWidth,
      borderWidth: this.item(1).strokeWidth,
      prefix: this.prefix,
      suffix: this.suffix,
      originalText: "A",
    });
  },

  _render(ctx: any) {
    this.callSuper("_render", ctx);
    ctx.save();
  },
});

BoundedText.fromObject = (options: any, callback: (obj: any) => any) => {
  return callback(new BoundedText(options));
};

const windowFabric: any = window.fabric;

windowFabric.TextBoxPro = BoundedText;

export default BoundedText;
