import styled from "styled-components";

const Style = styled.div`
  margin-left: 240px;

  #canvas-editor {
    background-color: #e5e5e5;
  }
`;

export default Style;
