import { fabric } from "fabric";

const ImagePlaceholder = fabric.util.createClass(fabric.Group, {
  type: "imagePlaceholder",

  initialize: function (options: any) {
    const img = new Image();

    img.src =
      "https://cdn.shopify.com/s/files/1/0279/9255/5625/products/10Forest_1c151f05-bc82-4ee1-b429-8a2c31907458_2000x.jpg?v=1620967181";

    const image = new fabric.Image(img, {
      originX: "center",
      originY: "center",
      width: 948 || 300,
      height: 948 || 300,
    });

    const rect = new fabric.Rect({
      strokeDashArray: [5, 5],
      originX: "center",
      originY: "center",
      stroke: "#808080",
      strokeWidth: 1,
      fill: "rgba(0, 0, 0, 0)",
      strokeUniform: true,
      globalCompositeOperation: "source-atop",
      width: options.width || 300,
      height: options.height || 300,
    });

    this.callSuper(
      "initialize",
      [image, rect],
      Object.assign(options, {
        fill: "transparent",
        strokeDashArray: [5, 5],
        async: true,
        superType: "husblizer",
        cornerColor: "#18a0fb",
        borderColor: "#18a0fb",
        borderScaleFactor: 2,
        lockScalingFlip: true,
        originX: "center",
        originY: "center",
        objectCaching: false,
        globalCompositeOperation: "source-atop",
      })
    );

    this.on({
      added: function () {
        this._updateScaledImage();
        this.canvas.renderAll();
      },
      moving: function () {
        this.canvas.renderAll();
      },
      scaling: function () {
        this.updateFromGroupScaling();
        this.canvas.renderAll();
      },
    });
  },

  _updateScaledImage: function () {
    if (this.width >= this.height) {
      this.item(0).scaleToHeight(this.height);
    } else {
      this.item(0).scaleToWidth(this.width);
    }

    this.item(0).left = this.item(1).left;
    this.item(0).top = this.item(1).top;
    this.item(0).angle = this.item(1).angle;

    this.canvas.renderAll();
  },

  updateFromGroupScaling: function () {
    const width = this.width * this.scaleX;
    const height = this.height * this.scaleY;

    this.scaleX = 1;
    this.scaleY = 1;

    this.setWidth(width);
    this.setHeight(height);

    this.canvas.renderAll();
  },

  setWidth: function (width: any) {
    if (!width) {
      width = 0;
    }
    this.item(1).set("width", width);
    this.set("width", width);
    this._updateScaledImage();
  },

  setHeight: function (height: any) {
    if (!height) {
      height = 0;
    }
    this.item(1).set("height", height);
    this.set("height", height);
    this._updateScaledImage();
  },

  _render(ctx: any) {
    this.callSuper("_render", ctx);
    ctx.save();
  },
});

ImagePlaceholder.fromObject = (options: any, callback: any) => {
  return callback(new ImagePlaceholder(options));
};

export default ImagePlaceholder;
