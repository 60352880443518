export const IconTextbox = () => {
  return (
    <svg
      className="svg"
      width="30pt"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5h1V2h5v14H5v1h7v-1H9V2h5v3h1V1H2v4z"
        fill-rule="nonzero"
        fill-opacity="1"
        fill="#000"
        stroke="none"
      ></path>
    </svg>
  );
};

export const IconImagePlaceholder = () => {
  return (
    <svg
      height="20"
      viewBox="0 0 512 512"
      width="30pt"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m497 110.332031c8.285156 0 15-6.714843 15-15v-80.332031c0-8.285156-6.714844-15-15-15h-80.332031c-8.285157 0-15 6.714844-15 15v25.164062h-291.335938v-25.164062c0-8.285156-6.714843-15-15-15h-80.332031c-8.285156 0-15 6.714844-15 15v80.332031c0 8.285157 6.714844 15 15 15h25.167969v233.746094.007813 57.578124h-25.167969c-8.285156 0-15 6.71875-15 15v80.335938c0 8.285156 6.714844 15 15 15h80.332031c8.285157 0 15-6.714844 15-15v-25.167969h291.335938v25.167969c0 8.285156 6.714843 15 15 15h80.332031c8.285156 0 15-6.714844 15-15v-80.335938c0-8.28125-6.714844-15-15-15h-25.167969v-57.578124c0-.003907 0-.007813 0-.011719v-233.742188zm-65.332031-80.332031h50.332031v50.332031h-50.332031zm-401.667969 0h50.332031v50.332031h-50.332031zm50.332031 452h-50.332031v-50.335938h50.332031zm401.667969 0h-50.332031v-50.335938h50.332031zm-65.332031-80.335938c-8.285157 0-15 6.71875-15 15v25.167969h-291.335938v-25.167969c0-8.28125-6.714843-15-15-15h-25.164062v-51.367187l81.398437-81.402344 69.730469 69.726563c5.855469 5.855468 15.351563 5.855468 21.210937 0l93.828126-93.824219 105.496093 105.5v51.371094h-25.164062zm25.164062-93.796874-94.890625-94.894532c-2.8125-2.8125-6.628906-4.390625-10.605468-4.390625-3.980469 0-7.796876 1.578125-10.609376 4.390625l-93.824218 93.828125-69.726563-69.726562c-5.859375-5.859375-15.355469-5.859375-21.214843 0l-70.796876 70.792969v-197.535157h25.167969c8.285157 0 15-6.714843 15-15v-25.167969h291.332031v25.167969c0 8.285157 6.71875 15 15 15h25.167969zm0 0"></path>
      <path d="m231.898438 112.109375c-30.417969 0-55.164063 24.746094-55.164063 55.164063 0 30.421874 24.746094 55.167968 55.164063 55.167968 30.417968 0 55.167968-24.746094 55.167968-55.167968 0-30.417969-24.75-55.164063-55.167968-55.164063zm0 80.332031c-13.875 0-25.164063-11.289062-25.164063-25.167968 0-13.875 11.289063-25.164063 25.164063-25.164063 13.878906 0 25.167968 11.289063 25.167968 25.164063 0 13.878906-11.289062 25.167968-25.167968 25.167968zm0 0"></path>
    </svg>
  );
};
