import { fabric } from "fabric";

const BackgroundPro = fabric.util.createClass(fabric.Rect, {
  type: "backgroundPro",
  _prevObjectStacking: null,
  image: null,
  rangeLeft: 0,
  rangeTop: 0,
  rangeAngle: 0,
  elementId: 1,

  initialize(rectOptions: any) {
    this.on("added", () => {
      const center = this.canvas.getCenter();
      this.top = center.top;
      this.left = center.left;

      if (rectOptions.src) {
        fabric.Image.fromURL(rectOptions.src, (myImg: any) => {
          myImg.set({
            originX: "center",
            originY: "center",
            width: myImg.width,
            height: myImg.height,
            crossOrigin: "anonymous",
            backgroundColor: "#fff",
          });

          this.canvas.setBackgroundImage(
            myImg,
            this.canvas.renderAll.bind(this.canvas)
          );

          if (myImg.width < myImg.height) {
            this.canvas.setViewportTransform([
              this.canvas.width / myImg.width - 0.1,
              0,
              0,
              this.canvas.width / myImg.width - 0.1,
              this.canvas.getCenter().left,
              this.canvas.getCenter().top,
            ]);
          } else {
            this.canvas.setViewportTransform([
              this.canvas.height / myImg.height - 0.1,
              0,
              0,
              this.canvas.height / myImg.height - 0.1,
              this.canvas.getCenter().left,
              this.canvas.getCenter().top,
            ]);
          }
        });
      } else {
        // const bgUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="
        const bgUrl =
          "https://st.quantrimang.com/photos/image/2020/07/30/Hinh-Nen-Trang-10.jpg";

        fabric.Image.fromURL(
          bgUrl,
          (myImg: any) => {
            myImg.set({
              originX: "center",
              originY: "center",
              width: rectOptions.width || 1181.1,
              height: rectOptions.height || 1181.1,
              crossOrigin: "anonymous",
              backgroundColor: "#fff",
            });

            const filter = new fabric.Image.filters.BlendColor({
              color: rectOptions.fill || "#fff",
              mode: "tint",
            });

            myImg.filters.push(filter);
            myImg.applyFilters();

            this.canvas.setBackgroundImage(
              myImg,
              this.canvas.renderAll.bind(this.canvas)
            );

            if (myImg.width < myImg.height) {
              this.canvas.setViewportTransform([
                this.canvas.width / myImg.width - 0.1,
                0,
                0,
                this.canvas.width / myImg.width - 0.1,
                this.canvas.getCenter().left,
                this.canvas.getCenter().top,
              ]);
              this.canvas.requestRenderAll();
              this.canvas.renderAll();
            } else {
              this.canvas.setViewportTransform([
                this.canvas.height / myImg.height - 0.1,
                0,
                0,
                this.canvas.height / myImg.height - 0.1,
                this.canvas.getCenter().left,
                this.canvas.getCenter().top,
              ]);
              this.canvas.requestRenderAll();
              this.canvas.renderAll();
            }
          },
          { crossOrigin: "anonymous" }
        );
      }

      this.canvas.renderAll();
    });

    this.on("mousedblclick", (e: any) => {
      this.canvas.centerObject(this);

      return this.canvas.renderAll();
    });

    this.on("selected", () => {
      this.selectable = true;
      this._prevObjectStacking = this.canvas.preserveObjectStacking;
      this.canvas.preserveObjectStacking = true;
      this.canvas.renderAll();
    });

    this.on("deselected", () => {
      this.canvas.preserveObjectStacking = this._prevObjectStacking;
      this.strokeWidth = 1;
      this.canvas.renderAll();
    });
  },

  setBackground: function (zoom: any) {
    const center = this.canvas.getCenter();

    this.canvas.zoomToPoint({ x: center.left, y: center.top }, zoom);

    return this.canvas.renderAll();
  },

  // render
  _render(ctx: any) {
    this.callSuper("_render", ctx);
    ctx.save();
  },
});

BackgroundPro.fromObject = (options: any, callback: any) => {
  return callback(new BackgroundPro(options));
};

var windowFabric: any = window.fabric;

windowFabric.BackgroundPro = BackgroundPro;

export default BackgroundPro;
