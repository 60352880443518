import React from "react";

import { IconImagePlaceholder } from "fragments/icons/index";

import Style from "./Style";

interface Props {
  addObject: any;
  canvas: any;
}

export default function index({ addObject, canvas }: Props) {
  return (
    <Style>
      <div className="icon-textbox" onClick={() => addObject(canvas)}>
        <IconImagePlaceholder />
      </div>
    </Style>
  );
}
