import React from "react";

import Style from "./Style";

interface Props {
  position: string;
  children: any;
}

export default function index({ position, children }: Props) {
  return <Style position={position}>{children}</Style>;
}
