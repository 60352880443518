import React from "react";

import { IconTextbox } from "fragments/icons/index";

import Style from "./Style";

interface Props {
  addObject: any;
  canvas: any;
}

export default function index({ addObject, canvas }: Props) {
  return (
    <Style>
      <div className="icon-textbox" onClick={() => addObject(canvas)}>
        <IconTextbox />
      </div>
    </Style>
  );
}
