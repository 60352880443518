import React, { useState, useEffect } from "react";

import Header from "components/layouts/Header/index";

import Tools from "components/tools/index";
import Named from "components/named/index";
import Shared from "components/shared/index";
import Panel from "components/layouts/Panel/index";

import Canvas from "canvas/Canvas";

import Textbox from "components/tools/Textbox/index";
import PlaceholderImage from "components/tools/PlaceholderImage/index";

import BackgroundPro from "canvas/objects/BackgroundPro";
import BoundedText from "canvas/objects/BoundedText";
import ImagePlaceholder from "canvas/objects/ImagePlaceholder";

import CreateObject from "canvas/handlers/CreateObject";

import Style from "./Style";

const windowFabric: any = window.fabric;
windowFabric.BackgroundPro = BackgroundPro;
windowFabric.BoundedText = BoundedText;
windowFabric.ImagePlaceholder = ImagePlaceholder;

function App() {
  const [canvas, setCanvas]: any = useState();

  useEffect(() => {
    canvas?.loadFromJSON({
      version: "4.3.1",
      objects: [
        {
          type: "backgroundPro",
          version: "4.3.1",
          originX: "center",
          originY: "center",
          left: 256,
          top: 449,
          width: 2000,
          height: 2000,
          fill: "#fff",
          scaleX: 1,
          scaleY: 1,
          opacity: 1,
          backgroundColor: "",
          globalCompositeOperation: "source-over",
          borderColor: "transparent",
        },
      ],
    });

    canvas?.renderAll();
  });

  return (
    <Style>
      <Header>
        <Tools>
          <Textbox canvas={canvas} addObject={CreateObject.addTextbox} />
          <PlaceholderImage
            canvas={canvas}
            addObject={CreateObject.addImagePlaceholder}
          />
        </Tools>
        <Named>Named </Named>
        <Shared>Shared</Shared>
      </Header>

      <Panel position="left">left</Panel>
      <Canvas setCanvas={setCanvas} />
      <Panel position="right">right</Panel>
    </Style>
  );
}

export default App;
