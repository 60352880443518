import styled, { css } from "styled-components";

type Props = {
  position: string;
};

const Style = styled.div<Props>`
  width: 240px;

  position: absolute;
  bottom: 0;
  top: 48px;
  ${({ position }) =>
    position === "left" &&
    css`
      left: 0px;
    `}

  ${({ position }) =>
    position === "right" &&
    css`
      right: 0px;
    `}

  background-color: #fff;
`;

export default Style;
