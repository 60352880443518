import ImagePlaceholder from "canvas/objects/ImagePlaceholder";

export default class CreateObject {
  static addTextbox(canvas: any) {}
  static addImagePlaceholder(canvas: any) {
    const imagePlaceholder = new ImagePlaceholder({
      width: 800,
      height: 600,
      top: 0,
      left: 0,
    });

    canvas.add(imagePlaceholder).setActiveObject(imagePlaceholder);
    canvas.renderAll();
  }
}
